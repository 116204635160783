@charset "utf-8";
@import "../settings";

.m-customer_voice {
  border-bottom: solid 1px #B3B3B3;
  tr:last-of-type td {
    padding-bottom: 50px;
  }
  ul {
    margin: 0 0 0 1em;
    padding: 0;
  }

  a:link,
  a:visited,
  a:hover,
  a:active {
    color: #3369a1;
    text-decoration: underline;
  }

  width: 100%;
  td {
    padding: 32px 48px;
  }
  &-title td {
    background-color: #004886;
    padding: 0;
    text-align: center;
    > p {
      color: white;
      font-size: 22px;
      line-height: 44px;
    }
  }

  &-header {
    &-logo {
      float: left;
      margin-right: 32px;
    }
    &-name {
      color: #1a1a1a;
      font-size: 16px;
      padding-top: 16px;
      padding-bottom: 16px;
    }
    &-text {
      color: #1a1a1a;
      font-size: 32px;
      line-height: 1.25em;
    }
  }

  &-description {
    td {
      padding-bottom: 0;
    }
    &-title {
      font-family: "SourceHanSansJP-Medium", sans-serif;
      color: #1a1a1a;
      font-size: 19px;
      margin-bottom: 13px;
      line-height: 1.25em;
      display: block;
    }
    &-sub_title {
      font-family: "SourceHanSansJP-Medium", sans-serif;
      color: #1a1a1a;
      font-size: 15px;
      margin-bottom: 10px;
      line-height: 1.25em;
      display: block;
    }
    &-one_pane,
    &-two_pane {
      div {
        font-size: 16px;
        color: #1a1a1a;
        line-height: 1.75em;
        margin-bottom: 64px;
        font-family: "SourceHanSansJP-Normal", sans-serif;
      }
    }
    &-two_pane {
      > div:first-of-type {
        float: left;
        > img {
          margin-right: 40px;
          margin-bottom: 16px;
        }
      }
      > div:last-of-type {
        float: right;
        > img {
          margin-left: 40px;
          margin-bottom: 16px;
        }
      }
      &-text {
        width: calc(100% - 360px);
      }
      &-image {
        width: 360px;
      }
      &:after {
        content: ' ';
        display: block;
        clear:both;
      }
    }
  }
  &-img_right {
    float: right;
    margin: 40px;
    margin-right: 0;
  }
  &-img_left {
    float: left;
    margin: 40px;
    margin-left: 0;
  }
  &-notice {
    display: block;
    font-size: 13px;
    margin-top: 12px;
  }
}
