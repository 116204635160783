@charset "utf-8";
@import "../settings";

.m-seminar-report {
  &_hero {
    height: 198px;
    background-color: #fff;
    text-align: center;
    font-size: 48px;
    margin-top:48px;
    &_logo {
      margin-top: 34px;
    }
    &_title {
      &_description {
        font-size: 16px;
        font-weight: 300;
        line-height: 28px;
      }
      img {
        margin-top: 48px;
        margin-right: 10px;
        margin-bottom: 52px;
        //height: 110px;
      }
      span {
        vertical-align: top;
        font-weight: 600;
        font-size: 22px;       
      }
    }
    &_title2 {
      margin-top: 60px;
      &_description {
        font-size: 16px;
        font-weight: 300;
        line-height: 28px;
      }
      &_description2 {
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
      }
      img {
        margin-top: 24px;
        margin-right: 10px;
        margin-bottom: 12px;
        //height: 110px;
      }
      span {
        vertical-align: top;
        font-weight: 600;
        font-size: 30px;
        line-height: 32px;  
      }
      a {
        &:link {color: #004886;}
        &:visited {color: #000080;}
        &:hover {color: #ff0000;}
        &:active {color: #ff8000;}
      }
    }
    &_title3 {
      margin-top: 60px;
      &_description {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 300;
        line-height: 28px;
      }
      img {
        margin-top: 24px;
        margin-right: 10px;
        margin-bottom: 12px;
        //height: 110px;
      }
      span {
        vertical-align: top;
        font-weight: 600;
        font-size: 30px;
        line-height: 32px;
      }
      a {
        &:link {color: #004886;}
        &:visited {color: #000080;}
        &:hover {color: #ff0000;}
        &:active {color: #ff8000;}
      }
    }
    &_message {
      margin-top:30px;
      font-size: 18px;
      color: #333;
    }
  }
}
