@charset "utf-8";
@import "../settings";
$imgWidth: 1280px;
$imgHeight: 850px;
$areaWidth: $contentsWidth;

.l-top {
  &_title {
    img {
      float: left;
    }
    h2 {
      font-family: 'SourceHanSansJP-Light', 'メイリオ', Meiryo, sans-serif;
      font-weight: lighter;
      font-size: 46px;
      line-height: 48px;
      float: left;
    }
  }
}

.m-top_hero {
  &_wrapper {
    height: 720px;
    background-image: url(../img/top-pc/top_img.jpg);
    background-size: cover;
    background-position: center top;
  }
  position: relative;
  width: $contentsWidth;
  height: 640px;
  left: 50%;
  transform: translate(-50%, 0);
  padding-top: 50px;
  > * {
    text-align: center;
  }
  &_eyecatch {
    position: relative;
    color: #fff;
    &_logo {
      margin-top: 46px;
    }
    &_copy1 {
      height: 36px;
      margin-top: 26px;
      font-size: 34px;
      font-family: 'SourceHanSansJP-Normal';
    }
    &_copy2 {
      margin-top: 16px;
      font-size: 54px;
      font-family: 'SourceHanSansJP-Light';
      font-weight: lighter;
    }
    &_copy3 {
      width: 632px;
      height: 40px;
      margin: 26px auto 0;
      background-color: white;
      color: #3369A1;
      line-height: 40px;
      margin-top: 315px;
      font-size: 16px;
      font-family: 'SourceHanSansJP-Light';
    }
    $eyecatchButtonWidth: 280px;
    $eyecatchButtonMargin: 16px;
    &_buttons {
      width: $eyecatchButtonWidth * 2 + $eyecatchButtonMargin * 2 + 4px;
      height: 100px;
      margin: 36px auto 0;
    }
    &_button {
      float: left;
      width: $eyecatchButtonWidth;
      height: 64px;
      border-radius: 32px;
      background-color: #FF9200;
      position: relative;
      cursor: pointer;
      margin: 0 $eyecatchButtonMargin - 7px;
      > div {
        cursor: pointer;
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translate(0, -50%);
      }
      label ~ label {
        margin-top: 8px;
      }
      &:hover {
        background-color: #F57200;
      }
      label {
        color: white;
        cursor: pointer;
        display: block;
      }
      label:first-of-type {
        font-size: 16px;
      }
      label:last-of-type {
        font-size: 22px;
      }
    }
  }

  &_bottom {
    margin:0 auto;
    width:100%;
    height: 200px;
    text-align:center;
    position:relative;
    left:50%;
    transform:translate(-50%, 0);
    background-color: white;
    &_news {
      width: $contentsWidth;
      height: 200px;
      margin: 0 auto;
      @include display-flex;
      &_tab {
        position:absolute;
        top: -50px;
        width: 120px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-weight: bold;
        background-color: #fff;
      }
      &_item {
        display: inline-block;
        padding-top: 32px;
      }
      dl {
        width: $contentsWidth/3;
        height: 200px - 32px;
        dt {
          padding-bottom: 20px;
        }
        dd {
          padding: 0 25px;
          color: #004886;
          font-size: 14px;
          line-height: 1.5em;
          text-align: center;
        }
      }
      a {
        &:link,
        &:visited,
        &:hover,
        &:active {
          text-decoration: none;
        }
        &:hover {
          background-color: rgba(0, 94, 217, 0.12);
        }
      }
    }
  }
}

.m-top_feature1 {
  background-color: #f7f7f7;
  &_title {
    height: 234px;
    img {
      margin-top: 46px;
    }
    h2 {
      margin-top: 94px;
      margin-left: 32px;
    }
    div {
      margin-top: 16px;
      float: left;
    }
  }
  &_chart {
    width: 100%;
    border-collapse: separate;
    border: none;
    td {
      border: solid 3px #f7f7f7;
      background-color: #fff;
      text-align: center;
      width: 25%;
      &:first-of-type {
        border-left:none;
      }
      &:last-of-type {
        border-right:none;
      }
    }
    tr:first-of-type td {
      border-top:none;
      height: 320px;
      img {
        margin-top: 38px;
      }
    }
    tr:last-of-type td {
      border-bottom:none;
      height: 234px;
      img {
        margin-top: 30px;
      }
    }
    &_subtitle1 {
      margin-top: 38px;
      margin-bottom: 32px;
      line-height: 32px;
      font-size: 24px;
      color: #333;
    }
    &_subtitle2 {
      margin-top: 16px;
      color: #333;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 16px;
    }
    &_description {
      font-size: 14px;
      color: #666;
    }
  }
  &_buttons {
    margin-top: 18px;
    margin-bottom: 64px;
    img ~ img {
      margin-left: 4px;
    }
  }
}

.m-top_feature2 {
  height: 800px;
  background-color: #fff;
  &_title {
    height: 128px;
    padding-top: 78px;
    img {
      margin-left: 160px;
    }
    h2 {
      text-align: center;
      margin-top: 16px;
      margin-left: 48px;
    }
  }
  &_location {
    width: 100%;
    margin-top: 94px;
    td {
      text-align: center;
      width: 50%;
    }
    &_subtitle {
      margin-top: 24px;
      margin-bottom: 16px;
      line-height: 32px;
      font-family: 'SourceHanSansJP-Light', 'メイリオ', Meiryo, sans-serif;
      font-size: 28px;
      color: #333;
      height: 70px;
    }
    &_description {
      font-size: 14px;
      line-height: 18px;
      color: #666;
    }
  }
}

.m-top_feature3 {
  height: 800px;
  background-color: #2779b7;
  color: #fff;
  &_title {
    height: 272px;
    padding-top: 78px;
    img {
      margin-left: 54px;
    }
    h2 {
      text-align: center;
      margin-top: 16px;
      margin-left: 32px;
      width: 510px;
    }
    div {
      text-align: center;
      margin-top: 16px;
      margin-left: 32px;
      width: 510px;
      float: left;
      line-height: 24px;
      font-size: 16px;
    }
  }
  &_buttons {
    text-align: center;
    margin-top: 36px;
    margin-bottom: 0px;
    img {
      border: $btnBorder;
    }
    img ~ img {
      margin-left: 14px;
    }
  }
}

.m-top_cases {
  height: 1160px;
  background-color: #f7f7f7;
  &_title {
    height: 200px;
    padding-top: 78px;
    img {
      margin-left: 136px;
    }
    h2 {
      text-align: center;
      margin-top: 16px;
      margin-left: 0;
      width: 600px;
    }
    div {
      text-align: center;
      margin-top: 16px;
      margin-left: 0;
      width: 600px;
      float: left;
      line-height: 24px;
      font-size: 16px;
    }
  }
  &_examples {
    height: 380px;
    width: 100%;
    text-align: center;
    padding: 0;
    a {
      display: inline-block;
      position:relative;
      width: 466px;
      height: 366px;
      background-color: #fff;
      border: solid 2px #b2b2b2;
      float: left;
      text-align: left;
      overflow: hidden;
      &:first-of-type {
        margin-right: 20px;
      }
    }
    &_icon {
      position: absolute;
      bottom: 36px;
      left: 27px;
      right: 20px;
      float: left;
    }
    & ~ & {
      margin-top: 10px;
    }
    &_description {
      margin-top: 22px;
      margin-left: 27 + 64 + 20px;
      margin-right: 25px;
      text-align: left;
      float: left;
    }
    &_company {
      display: block;
      color: #0063BE;
      font-size: 13px;
      line-height: 22px;
    }
    &_title {
      display: block;
      color: #1A1A1A;
      font-size: 16.5px;
      line-height: 26px;
    }
  }
  &_more {
    margin-top: 40px;
    width: 100%;
    text-align: center;
  }
}

.m-top_techinfo {
  height: 640px;
  background-color: #fff;
  &_title {
    height: 200px;
    padding-top: 78px;
    img {
      margin-left: 46px;
    }
    h2 {
      text-align: center;
      margin-top: 16px;
      margin-left: 24px;
      width: 640px;
    }
    div {
      text-align: center;
      margin-top: 16px;
      margin-left: 24px;
      width: 640px;
      float: left;
      line-height: 24px;
      font-size: 16px;
    }
  }
  &_linkbox {
    display: inline-block;
    width: 231px;
    height: 296px;
    border: $btnBorder;
    text-align: center;
    float: left;
    & ~ & {
      margin-left: 6px;
    }
    img {
      margin-top: 60px;
    }
  }
  &_linklabel {
    margin-top: 60px;
    font-size: 20px;
  }
}

$imgWidth: 230px;
.m-top_links {
  height: 262px;
  background-color: #3369a1;
  text-align: center;
  &_logo {
    margin-top: 32px;
  }
  &_buttons {
    margin-top: 24px;
    img ~ img {
      margin-left: ($contentsWidth - $imgWidth*4)/3 - 7px;
    }
  }
}
