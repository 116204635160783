@charset "utf-8";
@import "../settings";

.m-company {
  &_hero {
    height: 198px;
    background-color: $heroBackColor;
    text-align: center;
    font-size: 40px;
    &_title {
      span {
        vertical-align: top;
        line-height: 198px;
      }
    }
  }
  &_info {
    padding-top: 16px;
    padding-bottom: 100px;
    background-color: white;
    dl {
      float: left;
      padding-right: 64px;
      width: 416px;
    }
    dt {
      margin-top: 32px;
      margin-bottom: 4px;
      color: #333;
      font-size: 14px;
    }
    dd {
      color: #666;
      font-family: $fontLight;
      font-weight: lighter;
      line-height: 1.3em;
      font-size: 14px;
    }
  }
  &_team {
    padding-top: 36px;
    text-align: center;
    font-family: $fontLight;
    &_title {
      height: 204px;
      font-size: 38px;
      color: #333;
      border-bottom: solid 2px #b2b2b2;
      margin-bottom: 60px;
    }
    table {
      tr td:first-of-type {
        text-align: center;
        width: 240px;
        //img { width: 80px }
      }
      tr td:last-of-type {
        text-align: left;
        vertical-align: top;
        height: 230px;
      }
    }
    &_pos {
      font-size: 18px;
      color: #333;
      padding-bottom: 8px;
    }
    &_name {
      font-size: 30px;
      color: #333;
      padding-bottom: 30px;
    }
    &_desc {
      font-size: 14px;
      color: #333;
    }
  }
}
