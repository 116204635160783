@charset "utf-8";

.m-label-logo {
  display: inline-block;
  text-align: center;
  width: 150px;
  height: 90px;
  color: #fff;
  > div:first-of-type {
    font-size: 12px;
    line-height: 14px;
  }
  > div:nth-of-type(2) > span {
    font-family: 'SourceHanSansJP-ExtraLight', 'メイリオ', Meiryo, sans-serif;
    font-weight: lighter;
    font-size: 13px;
  }
}
@import "modules/header";
@import "modules/footer";
@import "modules/form";
@import "modules/dialog";
@import "modules/customer-voice";
@import "pages/cases";
@import "pages/index";
@import "pages/price";
@import "pages/contact";
@import "pages/send-complete";
@import "pages/company";
@import "pages/privacy-policy";
@import "pages/_seminar-report";
