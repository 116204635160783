@charset "utf-8";

@mixin display-flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -o-flex;
  display: flex;
}

@mixin flex-basis($length){
  -webkit-flex-basis: $length;
  -moz-flex-basis: $length;
//  -ms-flex-basis: $length;
  -o-flex-basis: $length;
  flex-basis: $length;
}

@mixin flex-grow($ratio){
  -webkit-flex-grow: $ratio;
  -moz-flex-grow: $ratio;
//  -ms-flex-grow: $ratio;
  -ms-flex: $ratio 0 auto;
  -o-flex-grow: $ratio;
  flex-grow: $ratio;
}

@mixin flex-direction($dir){
  -webkit-flex-direction: $dir;
  -moz-flex-direction: $dir;
  -ms-flex-direction: $dir;
  -o-flex-direction: $dir;
  flex-direction: $dir;
}

@mixin flex-shrink($ratio){
  -webkit-flex-shrink: $ratio;
  -moz-flex-shrink: $ratio;
  -ms-flex-shrink: $ratio;
  -o-flex-shrink: $ratio;
  flex-shrink: $ratio;
}

@mixin flex-wrap($type){
  -webkit-flex-wrap: $type;
  flex-wrap: $type;
}

@mixin flex-justify-content($type){
  -webkit-justify-content: $type;
  justify-content: $type;
}

@mixin flex-align-items($type){
  -webkit-align-items: $type;
  align-items: $type;
}

@mixin over_ellipsis {
  overflow : hidden;
  white-space : nowrap;
  text-overflow : ellipsis;
}

@mixin box_shadow_none(){
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
@mixin box_shadow($color, $x, $y, $sp){
  box-shadow: $color $x $y $sp;
  -webkit-box-shadow: $color $x $y $sp;
  -moz-box-shadow: $color $x $y $sp;
}
