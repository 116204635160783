@charset "utf-8";
@import "../settings";

.m-cases {
  &_hero {
    height: 198px;
    background-color: $heroBackColor;
    text-align: center;
    font-size: 48px;
    &_logo {
      margin-top: 34px;
    }
    &_title {
      img {
        margin-top: 15px;
        margin-right: 10px;
        //height: 110px;
      }
      span {
        vertical-align: top;
        line-height: 80px;
        font-family: $fontLight;
        font-weight: lighter;
      }
    }
    &_message {
      margin-top:30px;
      font-size: 18px;
      color: #333;
    }
  }
  &_tab {
    background-color: white;
    .l-base_container {
      padding: 34px 0 40px;
    }
    &_header {
      text-align: center;
      border-bottom: solid 2px #b2b2b2;
      height: 144px;
    }
    &_button {
      float: left;
      display: inline-block;
      width: 192px;
      height: 144px;
      font-size: 14px;
      color: #333;
      line-height: 1.1em;
      img {
        margin-top: 18px;
        margin-bottom: 16px;
      }
      &.js-tab_button--active {
        height: 140px;
        width: 188px;
        border: solid 2px #2779b7;
        img {
          margin-top: 16px;
        }
      }
    }
    &_content {
      padding: 50px 0 0;
      &_type {
        text-align: center;
        font-size: 24px;
        color: #333;
        font-family: $fontLight;
        line-height: 1.4em;
      }
      &_name {
        margin-top: 16px;
        text-align: center;
        font-size: 14px;
        color: #004987;
        line-height: 1.5em;
      }
      &_title {
        text-align: left;
        font-family: $fontLight;
        color: #333;
        line-height: 1.3em;
        margin: 50px auto 40px 80px;
        font-size: 16px;
      }
      &_chart {
        margin-left: 80px;
      }
      &_description {
        margin: 16px auto 40px 80px;
        font-size: 15px;
        color: #333;
        line-height: 1.5em;
        > li {
          margin-bottom: 12px;
        }
      }
      &_detail {
        border: solid 1px #b2b2b2;
        background-color: #fafafa;
        width: 100%;
        th {
          width: 160px;
          text-align: left;
          padding-left: 80px;
          font-size: 14px;
          color: #333;
          vertical-align: middle;
          font-family: $fontLight;
          font-weight: lighter;
        }
        td {
          color: #004987;
          vertical-align: middle;
          font-family: $fontLight;
          font-weight: lighter;
        }
        &_methods {
          height: 190px;
          td {
            img {
              height: 66px;
            }
            width: 180px;
            text-align: center;
          }
        }
        &_functions {
          height: 130px;
          td {
            white-space: nowrap;
            padding-right: 0px;
            text-align: left;
            line-height: 32px;
          }
        }
      }
    }
  }
  &_customer_voice {
    &-system_structure {
      margin-bottom: 0 !important;
    }
    &-system_chart {
    }
  }
}
