@charset "utf-8";
@import "../settings";

.dialog_wrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.dialog {
  display: none;
  z-index: 1001;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 100px 80px 100px;
  img {
    margin-bottom: 32px;
  }
  text-align: center;
  h3 {
    font-size: 16px;
    >*{
      vertical-align: middle;
    }
  }
  &_message {
    margin-top: 16px;
    margin-bottom: 32px;
    color: #333;
    font-size: 16px;
    line-height: 2em;
    text-align: left;
  }
  &_buttons {
    margin-top: 32px;
    button {
      margin: 0;
      border: $btnBorder;
      background-color: white;
      width: 240px;
      font-size: 20px;
      min-height: 50px;
      padding: 0;
    }
  }
}
