@charset "utf-8";
@import "../settings";

.m-send-complete {
  &_title {
    text-align: center;
    padding-top: 80px;
    font-size: 32px;
    color: #1a1a1a;
    > img {
      margin-bottom: 24px;
    }
  }
  &_description {
    width: 508px;
    margin: 60px auto;
    line-height: 30px;
    font-size: 18px;
    color: #1a1a1a;
  }
  &_back-to-home {
    text-align: center;
    > a {
      display: inline-block;
      border: solid 2px #004987;
      background-color: white;
      width: 240px;
      height: 48px;
      font-size: 18px;
      text-align: center;
      line-height: 48px;
      margin: 0 auto;
      margin-bottom: 180px;
    }
  }
}
