@charset "utf-8";
@import "../settings";

.m-linkbutton {
  &-long,
  &-short {
    display: inline-block;
    height: 24px;
    line-height: 24px;
    border-radius: 15px;
    border: $btnBorder;
    padding: 0;
    font-size: 14px;
  }
  &-long {
    width: 180px;
  }
  &-short {
    width: 80px;
  }
}

.m-form {
  &_required {
    color: #d00;
    font-size: 10px;
    vertical-align: top;
    margin-left: -0.5em;
  }
  &--warn {
    border: solid 1px red !important;
    background-color: rgb(255, 240, 240) !important;
  }
}

.m-form--warn[type="checkbox"] {
  & + label {
    border: solid 1px red !important;
    padding: 4px;
    background-color: rgb(255, 240, 240) !important;
  }
}
