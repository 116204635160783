@charset "utf-8";
@import "../settings";

.m-contact {
  &_hero {
    height: 160px;
    background-color: $heroBackColor;
    text-align: center;
    font-size: 44px;
    font-family: $fontLight;
    font-weight: lighter;
    &_title {
      &--mini {
        padding-top: 44px;
        padding-bottom: 8px;
        display: block;
        color: white;
        font-size: 26px;
        font-weight: bold;
      }
      h2 {
        vertical-align: top;
        font-family: $fontLight;
        font-weight: lighter;
      }
    }
  }
  &_tab {
    &_header {
      text-align: center;
      background-color: $heroBackColor;
      .l-base_wrapper {
        border-bottom: solid 2px #b2b2b2;
      }
    }
    &_button {
      display: inline-block;
      width: 192px;
      height: 56px;
      line-height: 56px;
      font-size: 14px;
      color: #333;
      &.js-tab_button--active {
        width: 188px;
        height: 52px;
        line-height: 52px;
        border: solid 2px #2779b7;
      }
      & ~ & {
        margin-left: 64px;
      }
    }
    &_content {
      text-align: center;
      padding-top: 32px;
    }
  }
  &_subtitle {
    font-size: 32px;
    color: #004987;
    font-family: $fontLight;
    font-weight: lighter;
    padding-bottom: 16px;
  }
  &_title {
  }
  &_desc {
    color: #666;
    padding-bottom: 32px;
    line-height: 1.5em;
  }
  &_table {
    width: 800px;
    margin-bottom: 80px;
    th {
      padding-right: 40px;
      width: 240px;
      text-align: right;
      font-family: $fontLight;
      font-weight: lighter;
      color: #666;
      line-height: 40px;
      height: 60px;
      vertical-align: top;
    }
    td {
      input {
        height: 36px;
        border: solid 1px #b2b2b2;
        background-color: white;
        
      }
    }
    &_text {
      &--full {
        width: 504px;
        padding: 0 8px;
        font-size: 15px;
      }
    }
    &_emailconfirm {
      span {
        display: inline-block;
        width: 40px;
      }
    }
    &_emailaccount {
      width: 260px;
      padding: 0 8px;
      font-size: 15px;
    }
    &_emaildomain {
      width: 180px;
      padding: 0 8px;
      font-size: 15px;
    }
    &_other {
      width: 504px;
      padding: 0 8px;
      font-size: 15px;
    }
    &_content {
      textarea {
        width: 504px;
        border: solid 1px #b2b2b2;
        height: 240px;
        padding: 8px;
        font-size: 15px;
      }
    }
    &_checkboxes {
      text-align: left;
      input {
        margin-right: 4px;
        width: 16px;
        height: 16px;
        vertical-align: middle;
      }
      label {
        display: inline-block;
        width: 210px;
        text-align: left;
        font-size: 13px;
        color: #333;
        white-space: nowrap;
      }
      &--other {
        label {
          width: 100px;
        }
        input[type="text"] {
          width: 330px;
          height: 24px;
        }
      }
    }
    &_privacyPolicy {
      th {
        padding-top: 16px;
      }
      td {
        padding: 16px 0;
        text-align: left;
        a {
          text-decoration: underline;
        }
      }
    }
    &_submit td {
      text-align: left;
      //padding-left: 340px;
      padding-top: 50px;
      button,
      input[type="submit"] {
        border: $btnBorder;
        background-color: white;
        width: 240px;
        font-size: 20px;
        min-height: 50px;
        padding: 0;
        &[disabled] {
          //border: none;
          background-color: #f2f2f2;
        }
      }
    }
  }
}
