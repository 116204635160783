@charset "utf-8";

@import "mixin";
@import "function";
@import "html5reset.min";
@import "settings";

@font-face {
  font-family: 'SourceHanSansJP-ExtraLight';
  src: url(/css/SourceHanSansJP/SourceHanSansJP-ExtraLight.otf) format('opentype');
  font-weight: lighter;
}
@font-face {
  font-family: 'SourceHanSansJP-Light';
  src: url(/css/SourceHanSansJP/SourceHanSansJP-Light.otf) format('opentype');
  font-weight: lighter;
}
@font-face {
  font-family: 'SourceHanSansJP-Normal';
  src: url(/css/SourceHanSansJP/SourceHanSansJP-Normal.otf) format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: 'SourceHanSansJP-Regular';
  src: url(/css/SourceHanSansJP/SourceHanSansJP-Regular.otf) format('opentype');
  font-weight: bold;
}
@font-face {
  font-family: 'SourceHanSansJP-Medium';
  src: url(/css/SourceHanSansJP/SourceHanSansJP-Medium.otf) format('opentype');
  font-weight: bolder;
}

html, body {
  font-family: 'SourceHanSansJP-Normal', 'メイリオ', Meiryo, sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #333;
  background-color: #f7f7f7;
  min-width: $contentsWidth;
}

a {
  color: #333;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

hr {
  border: solid 1px #999;
  border-bottom: none;
  border-right: none;
  border-left: none;
  margin: 0;
}

.l-base_wrapper {
  width: 100%;
}

.l-base_container {
  margin: 0 auto;
  width: $contentsWidth;
}

@import "modules";

