@charset "utf-8";
@import "../settings";

.m-footer {
  background-color: #3369a1;
  color: white;
  text-align: center;
  padding-top: 32px;
  padding-bottom: 24px;
  &_logo {
    span {
      vertical-align: top;
      font-size: 12px;
      line-height: 22px;
    }
  }
  &_links {
    margin-top: 38px;
    margin-bottom: 32px;
    img {
      border: $btnBorder;
    }
  }
  &_ncdc {
    line-height: 1.3em;
    &_comp {
      font-size: 14px;
    }
    &_copy {
      font-size: 12px;
    }
  }
}
