@charset "utf-8";
@import "../settings";

.m-price {
  &_hero {
    height: 198px;
    background-color: $heroBackColor;
    text-align: center;
    font-size: 40px;
    &_title {
      span {
        vertical-align: top;
        line-height: 198px;
      }
    }
  }
  &_tab {
    &_header {
      text-align: center;
      border-bottom: solid 2px #b2b2b2;
      &_message {
        margin-top: 48px;
        margin-bottom: 32px;
        font-size: 18px;
        color: #333;
        text-align: center;
      }
    }
    &_button {
      display: inline-block;
      width: 192px;
      height: 56px;
      line-height: 56px;
      font-size: 14px;
      color: #333;
      &.js-tab_button--active {
        width: 188px;
        height: 52px;
        line-height: 52px;
        border: solid 2px #2779b7;
      }
      & ~ & {
        margin-left: 210px;
      }
    }
    &_content {
      padding-top: 58px;
    }
  }
  &_table {
    margin-bottom: 65px;
    thead th {
      font-family: $fontLight;
      font-weight: lighter;
      vertical-align: top;
      font-size: 26px;
      color: #004987;
      height: 70px;
      text-align: left;
      padding-left: 35px;
      &:last-of-type {
        border-left: solid 2px #b2b2b2;
      }
    }
    tbody {
      th {
        width: 180px;
        text-align: left;
        font-family: $fontLight;
        font-weight: lighter;
        font-size: 16px;
        color: #666;
      }
      td {
        padding-left: 35px;
        height: 100px;
        &:first-of-type {
          width: 330px;
          padding-right: 30px;
          border-right: solid 2px #b2b2b2;
        }
      }
    }
    &_item {
      font-size: 24px;
      color: #333;
    }
    &_subitem {
      color: #555555;
      margin-bottom: 10px;
    }
    &_desc,
    &_desc--warn {
      font-size: 12px;
      color: #666;
      font-family: $fontLight;
      font-weight: lighter;
      margin-top: 8px;
      &--warn {
        color: #d00;
      }
    }
    &_spacer {
      th, td {
        padding-top: 35px;
      }
    }
  }
}
