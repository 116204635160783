@charset "utf-8";
@import "../settings";

.m-header,
.m-header_b {
  height: $gridSize;
  position: fixed;
  top: 0;
  &_inner {
    > * {
      float: left;
    }
  }
  $logoAreaWidth: 240px + 48px + 18px;
  &_logo {
    width: $logoAreaWidth;
    height: 46px;
    padding: ($gridSize - 46px)/2 0;
    font-size: 12px;
    line-height: 18px;
    > img {
      float: left;
      margin-right: 18px;
    }
  }
  &_links {
    font-size: 14px;
    @include display-flex;
    width: $contentsWidth - $logoAreaWidth;
    li {
      @include flex-grow(1);
      list-style: none;
      float: left;
      line-height: $gridSize;
      &:last-of-type {
        @include flex-grow(0);
      }
    }
  }
  z-index: 1000;
  & + * {
    margin-top: $gridSize;
  }
}

.m-header {
  background-color: rgba(247, 247, 247, 0.8);
  &_links {
    color: #333;
  }
}
.m-header_b {
  background-color: #3369a1;
  &_links {
    color: white;
  }
  a {
    color: white;
  }
}
