.m-privacyPolicy {
  .page-content-title {
    line-height: 3em;
    padding: 16px 32px;
  }
  .page-content {
    padding: 0 32px;
  }
  h5 {
    line-height: 2em;
  }
  p {
    line-height: 1.3em;
    margin-bottom: 16px;
  }
}
